import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
import versions from '../../../gen-src/versions.json';
export const pageTitle = "Setting up a project";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const Tabs = makeShortcode("Tabs");
const TabPane = makeShortcode("TabPane");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "setting-up-a-project",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#setting-up-a-project",
        "aria-label": "setting up a project permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up a project`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#requirements"
          }}>{`Requirements`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#choosing-the-artifacts"
          }}>{`Choosing the artifacts`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#setting-up-with-a-build-system"
          }}>{`Setting up with a build system`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#configure--parameters-javac-option"
          }}>{`Configure -parameters javac option`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#intellij-idea"
            }}>{`IntelliJ IDEA`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#using-maven-bom-for-simpler-dependency-management"
          }}>{`Using Maven BOM for simpler dependency management`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#requirements",
        "aria-label": "requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Requirements`}</h2>
    <p><a parentName="p" {...{
        "href": "https://adoptium.net/"
      }}>{`Java 8 (or later)`}</a>{` is required to build and run an application based on Armeria.`}</p>
    <Tip mdxType="Tip">
      <p>{`Use Java 19 (or later) if you are a contributor who tries to build Armeria itself.
See `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria/blob/main/CONTRIBUTING.md"
        }}>{`CONTRIBUTING.md`}</a>{` for more information.`}</p>
    </Tip>
    <h2 {...{
      "id": "choosing-the-artifacts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#choosing-the-artifacts",
        "aria-label": "choosing the artifacts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Choosing the artifacts`}</h2>
    <p>{`All Armeria JARs are available in `}<a parentName="p" {...{
        "href": "https://search.maven.org/search?q=g:com.linecorp.armeria%20-shaded"
      }}>{`Maven Central Repository`}</a>{`
under group ID `}<inlineCode parentName="p">{`com.linecorp.armeria`}</inlineCode>{` so that you can fetch them easily using your favorite build tool.
Add the Armeria artifacts that provide the desired functionality to your project dependencies. The following is
the list of major Armeria artifacts which might interest you:`}</p>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Artifact ID`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Description`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The core library.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-brave`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Distributed tracing with Brave.
See `}<a parentName="p" {...{
                "href": "/docs/advanced-zipkin"
              }}>{`Zipkin integration`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-bucket4j`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Support `}<a parentName="p" {...{
                "href": "https://github.com/bucket4j/bucket4j"
              }}>{`Bucket4j`}</a>{` based
`}<a parentName="p" {...{
                "href": "type://ThrottlingStrategy:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/throttling/ThrottlingStrategy.html"
              }}>{`type://ThrottlingStrategy`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-consul`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Consul based service discovery.
See `}<a parentName="p" {...{
                "href": "/docs/client-service-discovery#consul-based-service-discovery-with-consulendpointgroup"
              }}>{`Consul service discovery`}</a>{` and `}<a parentName="p" {...{
                "href": "/docs/server-service-registration#consul-based-service-registration-with-consulupdatinglistener"
              }}>{`Consul service registration`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-dropwizard2`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Provides a Dropwizard 2 Bundle around `}<inlineCode parentName="p">{`armeria-jetty9`}</inlineCode>{`.
See `}<a parentName="p" {...{
                "href": "/docs/advanced-dropwizard-integration"
              }}>{`Using Armeria with Dropwizard`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-eureka`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Eureka based service discovery.
See `}<a parentName="p" {...{
                "href": "/docs/client-service-discovery#eureka-based-service-discovery-with-eurekaendpointgroup"
              }}>{`Eureka service discovery`}</a>{` and `}<a parentName="p" {...{
                "href": "/docs/server-service-registration#eureka-based-service-registration-with-eurekaupdatinglistener"
              }}>{`Eureka service registration`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-graphql`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`GraphQL service support.
See `}<a parentName="p" {...{
                "href": "/docs/server-graphql"
              }}>{`Running a GraphQL service`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-grpc`}</inlineCode>{` and
`}<inlineCode parentName="p">{`armeria-grpc-kotlin`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`gRPC client and server support.
See `}<a parentName="p" {...{
                "href": "/docs/server-grpc"
              }}>{`Running a gRPC service`}</a>{`
and `}<a parentName="p" {...{
                "href": "/docs/client-grpc"
              }}>{`Calling a gRPC service`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-jetty11`}</inlineCode>{`,`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Embedded Jetty 11 Servlet container.
See `}<a parentName="p" {...{
                "href": "/docs/server-servlet"
              }}>{`Embedding a servlet container`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-kafka`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Enables sending access logs to Kafka`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-kotlin`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Kotlin support.
See `}<a parentName="p" {...{
                "href": "/docs/server-annotated-service#kotlin-coroutines-support"
              }}>{`Kotlin coroutines support`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-logback14`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Provides Logback `}<inlineCode parentName="p">{`Appender`}</inlineCode>{` implementation that adds
request information.
See `}<a parentName="p" {...{
                "href": "/docs/advanced-logging"
              }}>{`Logging contextual information`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-oauth2`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Support OAuth 2.0.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-protobuf`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Support Protocol Buffers in annotated service.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-retrofit2`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Allows using Armeria instead of OkHttp as transport layer
when using Retrofit2.
See `}<a parentName="p" {...{
                "href": "/docs/client-retrofit"
              }}>{`Retrofit integration`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-reactor3`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://projectreactor.io/"
              }}>{`Project Reactor`}</a>{` plugin`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-resilience4j2`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Support `}<a parentName="p" {...{
                "href": "https://github.com/resilience4j/resilience4j"
              }}>{`Resilience4j`}</a>{`
Circuit Breaker integration.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-resteasy`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Support `}<a parentName="p" {...{
                "href": "https://resteasy.dev/"
              }}>{`RESTEasy`}</a>{` integration.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-rxjava3`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://github.com/ReactiveX/RxJava"
              }}>{`RxJava 3`}</a>{` plugin`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-saml`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`SAML support`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-scalapb_2.12`}</inlineCode>{` and
`}<inlineCode parentName="p">{`armeria-scalapb_2.13`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Support ScalaPB for gRPC and annotated service.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-spring-boot3-actuator`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Spring Boot 3 Actuator integration. See
`}<a parentName="p" {...{
                "href": "/docs/advanced-spring-boot-integration#actuator-support"
              }}>{`Actuator support`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-spring-boot3-autoconfigure`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Spring Boot 3 integration. See
`}<a parentName="p" {...{
                "href": "/docs/advanced-spring-boot-integration"
              }}>{`Spring Boot integration`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-spring-boot3-webflux-autoconfigure`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Spring Boot 3 WebFlux integration. See
`}<a parentName="p" {...{
                "href": "/docs/advanced-spring-webflux-integration"
              }}>{`Using Armeria with Spring WebFlux`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-thrift0.18`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Thrift 0.18 client and server support.
See `}<a parentName="p" {...{
                "href": "/docs/server-thrift"
              }}>{`Running a Thrift service`}</a>{`
and `}<a parentName="p" {...{
                "href": "/docs/client-thrift"
              }}>{`Calling a Thrift service`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-tomcat10`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Embedded Tomcat 10 Servlet container.
See `}<a parentName="p" {...{
                "href": "/docs/server-servlet"
              }}>{`Embedding a servlet container`}</a>{`.`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`armeria-zookeeper3`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`ZooKeeper 3 based service discovery.
See `}<a parentName="p" {...{
                "href": "/docs/client-service-discovery#zookeeper-based-service-discovery-with-zookeeperendpointgroup"
              }}>{`ZooKeeper service discovery`}</a>{` and `}<a parentName="p" {...{
                "href": "/docs/server-service-registration#zookeeper-based-service-registration-with-zookeeperupdatinglistener"
              }}>{`ZooKeeper service registration`}</a>{`.`}</p></td></tr></tbody></table>
    <h2 {...{
      "id": "setting-up-with-a-build-system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up-with-a-build-system",
        "aria-label": "setting up with a build system permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up with a build system`}</h2>
    <p>{`You might want to use the following build file as a starting point when you set up a new project:`}</p>
    <Tabs mdxType="Tabs">
  <TabPane tab="Gradle" key="gradle" mdxType="TabPane">
    <CodeBlock language="groovy" filename="build.gradle" mdxType="CodeBlock">{`
    apply plugin: 'java'
    apply plugin: 'idea'
    apply plugin: 'eclipse'\n
    repositories {
        mavenCentral()
    }\n
    dependencies {
        // Adjust the list as you need.
        ['armeria',
        'armeria-brave',
        'armeria-grpc',
        'armeria-jetty11',
        'armeria-kafka',
        'armeria-logback12',
        'armeria-retrofit2',
        'armeria-rxjava3',
        'armeria-saml',
        'armeria-thrift0.18',
        'armeria-tomcat10',
        'armeria-zookeeper3'].each {
            implementation "com.linecorp.armeria:\${it}:${versions['com.linecorp.armeria:armeria-bom']}"
        }\n
        // Logging
        runtimeOnly 'ch.qos.logback:logback-classic:${versions['ch.qos.logback:logback-classic']}'
    }
    `}</CodeBlock>
  </TabPane>
  <TabPane tab="Gradle (Kotlin)" key="gradle_kotlin" mdxType="TabPane">
    <CodeBlock language="kotlin" filename="build.gradle.kts" mdxType="CodeBlock">{`
    apply(plugin = "java")
    apply(plugin = "idea")
    apply(plugin = "eclipse")\n
    repositories {
        mavenCentral()
    }\n
    dependencies {
        // Adjust the list as you need.
        listOf(
            "armeria",
            "armeria-brave",
            "armeria-grpc",
            "armeria-jetty9",
            "armeria-kafka",
            "armeria-logback",
            "armeria-retrofit2",
            "armeria-rxjava3",
            "armeria-saml",
            "armeria-thrift0.13",
            "armeria-tomcat9",
            "armeria-zookeeper3").forEach {
            implementation("com.linecorp.armeria:\${it}:${versions['com.linecorp.armeria:armeria-bom']}")
        }\n
        // Logging
        runtimeOnly("ch.qos.logback:logback-classic:${versions['ch.qos.logback:logback-classic']}")
    }
    `}</CodeBlock>
  </TabPane>
  <TabPane tab="Maven" key="maven" mdxType="TabPane">
    <CodeBlock language="xml" filename="pom.xml" mdxType="CodeBlock">{`
    <project xmlns="http://maven.apache.org/POM/4.0.0"
             xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
             xsi:schemaLocation="http://maven.apache.org/POM/4.0.0 http://maven.apache.org/xsd/maven-4.0.0.xsd">\n
      <modelVersion>4.0.0</modelVersion>
      <groupId>com.example</groupId>
      <artifactId>myproject</artifactId>
      <version>1.0-SNAPSHOT</version>
      <packaging>jar</packaging>
      <name>myproject</name>
      <url>https://example.com/</url>\n
      <properties>
        <project.build.sourceEncoding>UTF-8</project.build.sourceEncoding>
      </properties>\n
      <dependencies>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-brave</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-grpc</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-jetty9</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-kafka</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-logback</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-retrofit2</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-rxjava3</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-saml</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-thrift0.13</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-tomcat9</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <dependency>
          <groupId>com.linecorp.armeria</groupId>
          <artifactId>armeria-zookeeper3</artifactId>
          <version>${versions['com.linecorp.armeria:armeria-bom']}</version>
        </dependency>
        <!-- Logging -->
        <dependency>
          <groupId>ch.qos.logback</groupId>
          <artifactId>logback-classic</artifactId>
          <version>${versions['ch.qos.logback:logback-classic']}</version>
          <scope>runtime</scope>
        </dependency>
      </dependencies>
    </project>
    `}</CodeBlock>
  </TabPane>
    </Tabs>
    <h2 {...{
      "id": "configure--parameters-javac-option",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configure--parameters-javac-option",
        "aria-label": "configure  parameters javac option permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure `}<inlineCode parentName="h2">{`-parameters`}</inlineCode>{` javac option`}</h2>
    <p>{`You can configure your build tool to add `}<inlineCode parentName="p">{`-parameters`}</inlineCode>{` javac option as follows.`}</p>
    <Tabs mdxType="Tabs">
  <TabPane tab="Gradle" key="gradle" mdxType="TabPane">
    <CodeBlock language="groovy" filename="build.gradle" mdxType="CodeBlock">{`
    tasks.withType(JavaCompile) {
        options.compilerArgs += '-parameters'
    }
    `}</CodeBlock>
  </TabPane>
  <TabPane tab="Gradle (Kotlin)" key="gradle_kotlin" mdxType="TabPane">
    <CodeBlock language="kotlin" filename="build.gradle.kts" mdxType="CodeBlock">{`
    tasks.withType<JavaCompile> {
        options.compilerArgs.add("-parameters")
    }
    `}</CodeBlock>
  </TabPane>
  <TabPane tab="Maven" key="maven" mdxType="TabPane">
    <CodeBlock language="xml" filename="pom.xml" mdxType="CodeBlock">{`
    <project>
      <build>
        <plugins>
          <plugin>
            <groupId>org.apache.maven.plugins</groupId>
            <artifactId>maven-compiler-plugin</artifactId>
            <configuration>
              <compilerArgs>
                <arg>-parameters</arg>
              </compilerArgs>
            </configuration>
          </plugin>
        </plugins>
      </build>
    </project>
    `}</CodeBlock>
  </TabPane>
    </Tabs>
    <h3 {...{
      "id": "intellij-idea",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#intellij-idea",
        "aria-label": "intellij idea permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IntelliJ IDEA`}</h3>
    <p>{`Go to `}<inlineCode parentName="p">{`Preferences`}</inlineCode>{` > `}<inlineCode parentName="p">{`Build, Execution, Deployment`}</inlineCode>{` > `}<inlineCode parentName="p">{`Compiler`}</inlineCode>{` > `}<inlineCode parentName="p">{`Java Compiler`}</inlineCode>{`
and add `}<inlineCode parentName="p">{`-parameters`}</inlineCode>{` to `}<inlineCode parentName="p">{`Additional command line parameters`}</inlineCode>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.282208588957054%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAAB10lEQVQoz5WSzW6cMBRGeZx0W0Vtw/BjAzYYG7CByUymSRSpyiJRd32bLKqqfdBTQX6UaLpoFkeWvPh8vnsd9fMVfnvNMF/RTZeE3Q3KbUmlogsjhW4QlUaq+r+Ier/Fjztk1ZLkmlQopLIkouRLkhFn4l1EcX9PffhB6u85Nbd8bG757O6Ii5ZNlrPJ5buIPhweyO5+U3z/w+m3X5x8/cnJ4YFP6pwkSznLBJtcsMmOiV+dL4ZL1TBf0HYThbJU2q31RakpdE1eVGv1s2fS/Ij41aORrEeWxSxk1UAsHbkO5GVN4zqcD5huoG4dte3QxlI1LcpYVNOiW0dWVE+hkkhqjxsP65ZtOLCRFqEDiVQo02KHgO09/TjT+XENKlS9hjyTiII4e5qh0AMuHFbMsCcpFkNPIipqa/HTFtd7/DQzjNNquJi9qbzWfiSqzLTahfPr1XQJFPWjYeNnunmPHXc0w0Tdj+guUJiOpGxIlSGt3hK1w55hvqQ0E7IO5GogLTvivKJwAbOEhXOE6V8xINuBVLsjIusvVsOm36HdlsqMCO3ZiArjevowo22H6Txt72lcv7LcZYU6YjWc9jcswdrO61LSsmeTl+uwl6H/6wMnuSSV5RF/AZwltgRZb32NAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "intellij javac parameters",
          "title": "intellij javac parameters",
          "src": "/static/c778c5e23b435ba68d076f014b3a3824/a6d36/intellij_javac_parameters.png",
          "srcSet": ["/static/c778c5e23b435ba68d076f014b3a3824/222b7/intellij_javac_parameters.png 163w", "/static/c778c5e23b435ba68d076f014b3a3824/ff46a/intellij_javac_parameters.png 325w", "/static/c778c5e23b435ba68d076f014b3a3824/a6d36/intellij_javac_parameters.png 650w", "/static/c778c5e23b435ba68d076f014b3a3824/e548f/intellij_javac_parameters.png 975w", "/static/c778c5e23b435ba68d076f014b3a3824/3c492/intellij_javac_parameters.png 1300w", "/static/c778c5e23b435ba68d076f014b3a3824/133ae/intellij_javac_parameters.png 1424w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "using-maven-bom-for-simpler-dependency-management",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-maven-bom-for-simpler-dependency-management",
        "aria-label": "using maven bom for simpler dependency management permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using Maven BOM for simpler dependency management`}</h2>
    <p>{`You can import `}<inlineCode parentName="p">{`com.linecorp.armeria:armeria-bom`}</inlineCode>{` into your build rather than specifying Armeria versions in
more than one place. See `}<a parentName="p" {...{
        "href": "https://docs.gradle.org/current/userguide/platforms.html#sub:bom_import"
      }}>{`this article`}</a>{`
for Gradle and `}<a parentName="p" {...{
        "href": "https://www.baeldung.com/spring-maven-bom"
      }}>{`this article`}</a>{` for Maven to learn more about what
Maven BOM is and how to use it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      